<script setup lang="ts">
// composables
const route = useRoute("dashboard-organizations-orgId-org-pos-settings");
const { t } = useI18n();

useHead({
  title: t("pos-settings"),
});
</script>

<template>
  <div class="p-4">
    <PointOfSaleSettingsForm :orgId="route.params.orgId" />
  </div>
</template>
