<script setup lang="ts">
import { posSettingsSetInputSchema } from "@mono/validation/lib/Organization";

interface Props {
  orgId: string;
}

// props
const props = defineProps<Props>();

// composables
const { graphql, urql } = useUrqlClient();
const { t } = useI18n();
const snack = useSnack();

// query
const query = graphql(/* GraphQL */ `
  query PosSettingsByPk($orgId: uuid!) {
    posSettings_by_pk(orgId: $orgId) {
      printFooterNotes
      printHeaderNotes
      updatedAt
    }
  }
`);

const { fetching, data } = urql.useQuery({
  query,
  variables: { orgId: props.orgId },
});

// mutation
const mutation = graphql(/* GraphQL */ `
  mutation PosSettingUpsert($orgId: uuid!, $_set: posSettings_set_input!) {
    upsert_posSettings_by_pk(pk_columns: { orgId: $orgId }, _set: $_set) {
      updatedAt
    }
  }
`);

const {
  handleSubmit,
  isSubmitting,
  disableSubmit,
  values,
  setFieldValue,
  setValues,
} = useZodForm(posSettingsSetInputSchema);

// watch
watch(
  data,
  (updatedData) => {
    if (updatedData?.posSettings_by_pk) {
      setValues(updatedData.posSettings_by_pk);
    }
  },
  { immediate: true }
);

// methods
const submit = handleSubmit(async (v) => {
  try {
    const { error } = await urql.useMutation(mutation).executeMutation({
      orgId: props.orgId,
      _set: {
        printFooterNotes: v.printFooterNotes ? v.printFooterNotes : null,
        printHeaderNotes: v.printHeaderNotes ? v.printHeaderNotes : null,
      },
    });

    if (error) {
      throw error;
    }

    snack.success();
  } catch (error) {
    snack.error();
  }
});
</script>

<template>
  <MCard
    :loading="isSubmitting || fetching"
    :disabled="isSubmitting || fetching"
  >
    <template #title>
      <div class="flex items-center justify-between">
        <div>
          <h1 class="text-xl">
            {{ t("pos-settings") }}
          </h1>
        </div>
        <div></div>
      </div>
      <MDivider class="my-4" />
    </template>
    <template #text>
      <MForm
        class="flex flex-col gap-y-1"
        @submit="submit"
      >
        <MarkdownInput
          :label="t('print-header-notes')"
          name="printHeaderNotes"
          :modelValue="values.printHeaderNotes || ''"
          @update:modelValue="setFieldValue('printHeaderNotes', $event)"
        />
        <MarkdownInput
          :label="t('print-footer-notes')"
          name="printFooterNotes"
          :modelValue="values.printFooterNotes || ''"
          @update:modelValue="setFieldValue('printFooterNotes', $event)"
        />
        <MDivider class="my-4" />

        <MBtn
          :disabled="disableSubmit"
          :loading="isSubmitting"
          type="submit"
          block
        >
          {{ t("update") }}
        </MBtn>
      </MForm>
    </template>
  </MCard>
</template>
